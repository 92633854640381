import React, { useEffect, useState } from "react";
import SideMenu from "../sidemenu";
import "./Branches.scss";
import { Button, Checkbox, message, TimePicker } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import api from "../../api";
import { SidebarSection } from "../../constants/constant";
import SideBarHeader from "../header/LoggedInHeader";
import { ToastContainer, toast } from "react-toastify";
import { RestaurantBranchInterface } from "./interFace";
import Loader from "../Loader";
import { RestaurantDetailInterface } from "../restaurant-list/interFace";
// import qrIcon from "./img/qr_code.png";
// import { BranchMenuDetailsInterface } from "./BranchMenuDetailsInterface";
import { useNavigate } from "react-router-dom";
// import { QRCode } from "antd/lib";
import QRCode from "qrcode.react";
import GoogleMapComp from "./googleMap";
import Pagination from "./pagination";
let baseURL = process.env.REACT_APP_API_URL;

function Branches() {
  const [search, setSearch] = useState("");
  const [branchId, setBranchId] = useState("");
  const [loading, setLoading] = useState(false);
  const [isApproved, setIsApproved] = useState(true);
  const [branchData, setBranchData] = useState<RestaurantBranchInterface[]>([]);
  const [branchDetails, setBranchDetails] =
    useState<RestaurantDetailInterface>();
  // const [branchMenuDetails, setBranchMenuDetails] =
  //   useState<BranchMenuDetailsInterface>();
  // const [searchText, setSearchText] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [contactNumber, setcontactNumber] = useState("");
  const [tagline, setTagline] = useState("");
  const [contactPersonName, setContactPersonName] = useState("");
  const [contactPersonEmail, setContactPersonEmail] = useState("");
  const [contactPersonContact, setContactPersonContact] = useState("");
  const [isOpenNow, setIsOpenNow] = useState(false);
  // const [userData, setUserData] = useState(null);
  const [restaurantId, setRestaurantId] = useState("");
  const [showNav, setShowNav] = useState(true);
  const format = "HH:mm";
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteClose = () => setShowDeleteModal(false);
  const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
  const [showViewBranchModal, setShowViewBranchModal] = useState(false);
  const handleViewBranchClose = () => {
    setBranchId("");
    // setBranchMenuDetails(undefined);
    setShowViewBranchModal(false);
  };
  const navigate = useNavigate();
  const handleStatusUpdateClose = () => setShowStatusUpdateModal(false);
  const ITEMS_PER_PAGE = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  // const endIndex = startIndex + ITEMS_PER_PAGE;
  // const currentData = branchData.slice(startIndex, endIndex);
  const [sortedData, setSortedData] = useState([]);
  let [errors, setErrors] = useState({
    name: "",
    email: "",
    address: "",
    contactNumber: "",
    contactPersonEmail: "",
    contactPersonContact: "",
    isOpenNow: "",
    contactPersonName: "",
  });
  const handleStatusUpdateShow = (id: string) => {
    setShowStatusUpdateModal(true);
    setBranchId(id);
  };
  const [branchStatus, setBranchStatus] = useState("");
  const handleDeleteShow = (id: string) => {
    setShowDeleteModal(true);
    setBranchId(id);
  };

  const viewAddBranch = async () => {
    setBranchId("");
    reInitiateWorkingHours();
    clearFormFields();
    handleShow();
  };

  // const handleViewBranchDetailsShow = async (id: string) => {
  //   setBranchId(id);
  //   console.log("branchhh " + id);
  //   setLoading(true);
  //   try {
  //     const response = await api
  //       .get(
  //         "api/identity/restaurant-branch/" +
  //         id +
  //         "?restaurantId=" +
  //         restaurantId
  //       )
  //       .then(function (response) {
  //         setLoading(false);
  //         console.log("sucess respp get branch details");
  //         console.log(response.data);

  //         setBranchDetails(response.data.data);

  //         let sortedData = branchDetails.workingHour;
  //         sortedData = sortedData.sort((a, b) => (a.day < b.day ? -1 : 1));;
  //         console.log("Sorted data:" + JSON.stringify(sortedData));
  //         setSortedData(sortedData);
  //         setShowViewBranchModal(true);
  //         //getBranchMenu(response.data.data.menuAssignmentId,id);
  //         return response.data;
  //       })
  //       .catch(function (error) {
  //         console.log("Show error notification!");
  //         toast.error("Error in api call!");
  //         setLoading(false);
  //         console.log(error);
  //         return Promise.reject(error);
  //       });
  //   } catch (error) {
  //     console.error("There was an error!", error);
  //   }
  // };
  const handleViewBranchDetailsShow = async (id: string) => {
    setBranchId(id);
    console.log("branchhh " + id);
    setLoading(true);
    try {
      const response = await api.get(
        `api/identity/restaurant-branch/${id}?restaurantId=${restaurantId}`
      );

      setLoading(false);
      console.log("success response get branch details");
      console.log(response.data);

      const branchData = response.data.data;

      let sortedData = branchData.workingHour.sort((a, b) =>
        a.day < b.day ? -1 : 1
      );
      console.log("Sorted data:", JSON.stringify(sortedData));

      setBranchDetails(branchData);
      setSortedData(sortedData);
      setShowViewBranchModal(true);
      return response.data;
    } catch (error) {
      console.log("Show error notification!");
      message.error("Error in API call!");
      setLoading(false);
      console.error("There was an error!", error);
      return Promise.reject(error);
    }
  };

  // const getBranchMenu = async (
  //   menuAssignmentId: string,
  //   selectedBranchId: string
  // ) => {
  //   console.log(" menuAssignmentId " + menuAssignmentId);
  //   setLoading(true);
  //   try {
  //     await api
  //       .get(
  //         "api/restaurant/branch-menu/assigned-menu/" +
  //         menuAssignmentId +
  //         "?branchId=" +
  //         selectedBranchId +
  //         "&restaurantId=" +
  //         restaurantId
  //       )
  //       .then(function (response) {
  //         setLoading(false);
  //         console.log("sucess respp get branch details");
  //         console.log(response.data);
  //         setBranchMenuDetails(response.data.data);
  //         return response.data;
  //       })
  //       .catch(function (error) {
  //         console.log("Show error notification!");
  //         toast.error("Error in api call!");
  //         setLoading(false);
  //         console.log(error);
  //         return Promise.reject(error);
  //       });
  //   } catch (error) {
  //     console.error("There was an error!", error);
  //   }
  // };

  useEffect(() => {
    const loggedInUserData = localStorage.getItem("loggedInUser");
    if (loggedInUserData) {
      // setUserData(JSON.parse(loggedInUserData));
      const parsedData = JSON.parse(loggedInUserData);
      setRestaurantId(parsedData.restaurantId);
    }
    setShowMap(false);
  }, []);
  useEffect(() => {
    if (restaurantId) {
      fetchRestaurantDetailData(restaurantId);
    }
  }, [restaurantId]);
  const fetchRestaurantDetailData = async (id: string) => {
    try {
      const response = await api.get(`/api/identity/restaurant/${id}`);
      setIsApproved(response.data.data.isApproved)
    } catch (error) {
      console.error("Error fetching restaurant detail data:", error);
      return null;
    }
  };

  useEffect(() => {
    if (restaurantId) {
      getallBranch(currentPage);
    }
  }, [restaurantId, currentPage, search]);

  //Function for Get all branches
  const getallBranch = async (pageNo: any) => {
    try {
      var url = "";
      url =
        `api/identity/restaurant-branch/?page=${pageNo}&limit=${ITEMS_PER_PAGE}&restaurantId=${restaurantId}&searchText=` +
        search;
      const response = await api.get(url);
      //setLoading(false);
      console.log("success response: ", response.data.data);

      // Update branch data
      setBranchData(response.data.data.branch);

      // Update total pages
      const totalCount = response.data.data.totalCount;
      const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE);
      setTotalPages(totalPages);
      console.log("totalPages count ", totalPages);

      return response.data;
    } catch (error) {
      console.error("There was an error!", error);
      message.error("Error in API call!");
      return Promise.reject(error);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value;
    setSearch(searchText);
    setCurrentPage(1); // Reset to the first page whenever search text changes
  };

  const [show, setShow] = useState(false);
  const handleOpenContactUs = () => {
    // alert("Contact Us\n+971 50724 5494\nEmail Us\nsales@innovatechsoft.com");
    setShow(true);
  };
  const handleCloseContactUs= () =>{
    setShow(false);
  }
  // const nextPage = () => {
  //   setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  // };

  // const prevPage = () => {
  //   setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  // };

  // const goToPage = (pageNumber: any) => {
  //   setCurrentPage(Math.min(Math.max(pageNumber, 1), totalPages));
  // };

  const updateWorkingHoursArray = (
    event: any,
    item: any,
    item1: any,
    index: any,
    index1: any,
    field: any
  ) => {
    if (field === "isOpen" || field === "startTime" || field === "endTime") {
      let updatedWorkingHours = [];
      for (let i = 0; i < workingHrs.length; i++) {
        const element = workingHrs[i];
        if (index === i) {
          let newValue = [];

          for (let j = 0; j < workingHrs[i].value.length; j++) {
            if (index1 === j) {
              if (field === "isOpen") {
                let newVal = {
                  ClosesAt: workingHrs[i].value[j].ClosesAt,
                  day: workingHrs[i].value[j].day,
                  id: workingHrs[i].value[j].id,
                  isOpen: event.target.checked,
                  openAt: workingHrs[i].value[j].openAt,
                };
                newValue.push(newVal);
              } else if (field === "startTime") {
                let newVal = {
                  ClosesAt: workingHrs[i].value[j].ClosesAt,
                  day: workingHrs[i].value[j].day,
                  id: workingHrs[i].value[j].id,
                  isOpen: workingHrs[i].value[j].isOpen,
                  openAt: event,
                };
                newValue.push(newVal);
              } else if (field === "endTime") {
                let newVal = {
                  ClosesAt: event,
                  day: workingHrs[i].value[j].day,
                  id: workingHrs[i].value[j].id,
                  isOpen: workingHrs[i].value[j].isOpen,
                  openAt: workingHrs[i].value[j].openAt,
                };
                newValue.push(newVal);
              }
            } else {
              newValue.push(workingHrs[i].value[j]);
            }
          }
          let newElement = {
            key: element.key,
            value: newValue,
          };
          updatedWorkingHours.push(newElement);
        } else {
          updatedWorkingHours.push(element);
        }
      }
      setWorkingHrs(updatedWorkingHours);
    } else {
      let data = workingHrs;
      for (let i = 0; i < workingHrs.length; i++) {
        const element = workingHrs[i];
        let key = element.key;
        let value = element.value;
        let newValue = [];
        if (index === i) {
          // item1[field]=;
          newValue.push(item1);
          data[i] = {
            key: key,
            value: newValue,
          };
        } else {
          newValue.push(value);
          data[i] = {
            key: key,
            value: newValue,
          };
        }
      }
      setWorkingHrs(data);
    }
  };

  const addWorkingHours = (item: any, index: any, item1: any, index1: any) => {
    // debugger;
    let data = [];
    for (let i = 0; i < workingHrs.length; i++) {
      const element = workingHrs[i];
      let key = element.key;
      let value = element.value;
      if (index === i) {
        let newItem = {
          id: !value || value.length === 0 ? 1 : value.length,
          day: item1.day,
          openAt: "7:00",
          ClosesAt: "13:00",
          isOpen: false,
        };
        value.push(newItem);
        let newElement = {
          key: key,
          value: value,
        };
        data.push(newElement);
      } else {
        data.push(element);
      }
    }
    setWorkingHrs(data);
  };

  const deleteWorkingHours = (
    item: any,
    index: any,
    item1: any,
    index1: any
  ) => {
    let data = [];
    for (let i = 0; i < workingHrs.length; i++) {
      const element = workingHrs[i];
      let key = element.key;
      let value = element.value;
      if (index === i) {
        if (value && value.length > 0) {
          for (let j = 0; j < value.length; j++) {
            if (index1 === j) {
              value.splice(index1, 1);
            }
          }
        }
        let newElement = {
          key: key,
          value: value,
        };
        data.push(newElement);
      } else {
        data.push(element);
      }
    }
    setWorkingHrs(data);
  };

  const deleteBranch = async () => {
    setLoading(true);
    try {
      await api
        .delete(
          "api/identity/restaurant-branch/" +
            branchId +
            "?restaurantId=" +
            restaurantId
        )
        .then(function (response) {
          console.log("sucess respp");
          message.success("Deleted Successfully!");
          setLoading(false);
          console.log(response.data);
          setBranchId("");
          handleDeleteClose();
          getallBranch(currentPage);
          return response.data;
        })
        .catch(function (error) {
          console.log("Show error notification!");
          message.error(error.response.data.message);
          setLoading(false);
          console.log(error);
          return Promise.reject(error);
        });
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  const [showMap, setShowMap] = useState(false);
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "name") {
      setName(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "address") {
      setAddress(value);
    } else if (name === "contactNumber") {
      setcontactNumber(value);
    } else if (name === "contactPersonName") {
      setContactPersonName(value);
    } else if (name === "contactPersonContact") {
      setContactPersonContact(value);
    } else if (name === "contactPersonEmail") {
      setContactPersonEmail(value);
    } else if (name === "isOpenNow") {
      setIsOpenNow(e.target.checked);
    }
  };

  const isValid = () => {
    var valid = true;
    var newErrors = {
      name: "",
      email: "",
      address: "",
      contactNumber: "",
      contactPersonName: "",
      contactPersonEmail: "",
      contactPersonContact: "",
      isOpenNow: "",
    };

    // Name validation
    if (!name.trim()) {
      newErrors = { ...newErrors, name: "Name is required" };
      valid = false;
    } else {
      newErrors = { ...newErrors, name: "" }; // Clear the error if name is provided
      console.log("name error cleared");
    }

    // Email validation
    if (!email.trim()) {
      newErrors = { ...newErrors, email: "Email is required" };
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors = { ...newErrors, email: "Email is invalid" };
      valid = false;
    } else {
      newErrors = { ...newErrors, email: "" }; // Clear the error if email is provided and valid
      console.log("email error cleared");
    }

    // Address validation
    if (!address.trim()) {
      newErrors = { ...newErrors, address: "Address is required" };
      valid = false;
    } else {
      newErrors = { ...newErrors, address: "" }; // Clear the error if address is provided
      valid = false;
      console.log("Address error cleared");
    }

    // Contact validation
    if (!contactNumber.trim()) {
      newErrors = { ...newErrors, contactNumber: "Contact is required" };
      valid = false;
    } else {
      newErrors = { ...newErrors, contactNumber: "" }; // Clear the error if contact number is provided
    }

    // Contact name
    if (!contactPersonName.trim()) {
      newErrors = {
        ...newErrors,
        contactPersonName: "Contact person name is required",
      };
      valid = false;
    } else {
      newErrors = { ...newErrors, contactPersonName: "" }; // Clear the error if contact person name is provided
    }

    // Contact email
    if (!contactPersonEmail.trim()) {
      newErrors = {
        ...newErrors,
        contactPersonEmail: "Contact person email is required",
      };
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(contactPersonEmail)) {
      newErrors = {
        ...newErrors,
        contactPersonEmail: "Contact person email is invalid",
      };
      valid = false;
    } else {
      newErrors = { ...newErrors, contactPersonEmail: "" }; // Clear the error if contact person email is provided and valid
    }

    // Contact contact
    if (!contactPersonContact.trim()) {
      newErrors = {
        ...newErrors,
        contactPersonContact: "Contact person number is required",
      };
      valid = false;
    } else {
      newErrors = { ...newErrors, contactPersonContact: "" }; // Clear the error if contact person contact is provided
      valid = true;
    }
    if (!valid) {
      setErrors(newErrors);
      console.log("error count: " + Object.keys(newErrors).length);
    }
    return valid;
  };

  const addOrUpdateBranch = async () => {
    if (isValid()) {
      console.log(JSON.stringify(workingHrs));

      let data = [];
      for (let index = 0; index < workingHrs.length; index++) {
        if (workingHrs[index]?.value) {
          for (
            let index1 = 0;
            index1 < workingHrs[index].value.length;
            index1++
          ) {
            data.push(workingHrs[index].value[index1]);
          }
        }
      }
      console.log(JSON.stringify(data));
      let finalWorkingHours = [];
      for (let index = 0; index < data.length; index++) {
        let obj = {
          isOpen: false,
          day: 0,
          startTime: "",
          endTime: "",
        };
        const element = data[index];
        if (null != element) {
          obj.isOpen = element ? element.isOpen : false;
          obj.day = element ? element.day : 0;
          obj.startTime = element ? element.openAt : "";
          obj.endTime = element ? element.ClosesAt : "";
          finalWorkingHours.push(obj);
        }
      }
      console.log(JSON.stringify(finalWorkingHours));
      console.log("Form submitted successfully!");
      setLoading(true);
      let url = "";
      if (branchId === "") {
        url = "api/identity/restaurant-branch/";
        const latitude = lat || 0;
        const longitude = lng || 0;

        let payload = {
          restaurantId: restaurantId,
          branch: [
            {
              email: email,
              name: name,
              tagline: tagline,
              address: address,
              contactNumber: contactNumber,
              isOpenNow: isOpenNow,
              workingHour: finalWorkingHours,
              contactPersonName: contactPersonName,
              contactPersonEmail: contactPersonEmail,
              contactPersonContact: contactPersonContact,
              mapLocationLatitude: latitude,
              mapLocationLongitude: longitude,
            },
          ],
        };
        console.log("add  branch " + JSON.stringify(payload));
        try {
          await api
            .post(url, payload)
            .then(function (response) {
              message.success(response.data.message);
              console.log("sucess respp");
              setLoading(false);

              console.log(response.data);
              handleClose();
              getallBranch(currentPage);
              clearFormFields();
              return response.data;
            })
            .catch(function (error) {
              console.log("Show error notification!");
              setLoading(false);
              message.error(error.response.data.message);
              console.log(error);
              return Promise.reject(error);
            });
        } catch (error) {
          console.error("There was an error!", error);
        }
      } else {
        const latitude = lat || 0;
        const longitude = lng || 0;
        let payload = {
          restaurantId: restaurantId,
          name: name,
          tagline: tagline,
          address: address,
          contactNumber: contactNumber,
          isOpenNow: isOpenNow,
          isActive: true,
          workingHour: finalWorkingHours,
          contactPersonName: contactPersonName,
          contactPersonEmail: contactPersonEmail,
          contactPersonContact: contactPersonContact,
          mapLocationLatitude: latitude,
          mapLocationLongitude: longitude,
        };
        url = "api/identity/restaurant-branch/" + branchId;
        console.log(" update branch " + JSON.stringify(payload));
        try {
          await api
            .put(url, payload)
            .then(function (response) {
              message.success(response.data.message);
              console.log("sucess respp");
              setLoading(false);
              // message.success("Branch updated Successfully!");
              console.log(response.data);
              setBranchId("");
              handleClose();
              getallBranch(currentPage);
              return response.data;
            })
            .catch(function (error) {
              console.log("Show error notification!");
              setLoading(false);
              message.error("Error in API call!");
              setBranchId("");
              console.log(error);
              return Promise.reject(error);
            });
        } catch (error) {
          console.error("There was an error!", error);
        }
      }
    }
  };

  const viewBranchMenu = (menuId: string) => {
    console.log(
      "branchId: " +
        branchId +
        " menuId: " +
        menuId +
        " restautantId: " +
        restaurantId
    );
    navigate(
      "/restaurant?a=" + menuId + "&b=" + branchId + "&c=" + restaurantId
    );
  };
  const updateStatus = async () => {
    setLoading(true);
    console.log(" update status " + branchStatus);
    try {
      await api
        .put(
          "api/identity/restaurant-branch/status/" +
            branchId +
            "?restaurantId=" +
            restaurantId +
            "&statusCode=" +
            branchStatus
        )
        .then(function (response) {
          message.success("Status updated Successfully!");
          console.log("Status updated Successfully!");
          setLoading(false);
          console.log(response.data);
          handleStatusUpdateClose();
          setBranchId("");
          setBranchStatus("");
          getallBranch(currentPage);
          clearFormFields();

          return response.data;
        })
        .catch(function (error) {
          console.log("Show error notification!");
          message.error("Error in API call!");
          setLoading(false);
          console.log(error);
          return Promise.reject(error);
        });
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  const clearFormFields = () => {
    setName("");
    setEmail("");
    setTagline("");
    setAddress("");
    setcontactNumber("");
    setIsOpenNow(false);
    setContactPersonName("");
    setContactPersonEmail("");
    setContactPersonContact("");
  };

  const [workingHrs, setWorkingHrs] = useState<any>([
    {
      key: "Monday",
      value: [
        { id: 1, day: 1, openAt: "7:00", ClosesAt: "13:00", isOpen: false },
      ],
    },
    {
      key: "Tuesday",
      value: [
        { id: 1, day: 2, openAt: "7:00", ClosesAt: "13:00", isOpen: false },
      ],
    },
    {
      key: "Wednesday",
      value: [
        { id: 1, day: 3, openAt: "7:00", ClosesAt: "13:00", isOpen: false },
      ],
    },
    {
      key: "Thursday",
      value: [
        { id: 1, day: 4, openAt: "7:00", ClosesAt: "13:00", isOpen: false },
      ],
    },
    {
      key: "Friday",
      value: [
        { id: 1, day: 5, openAt: "7:00", ClosesAt: "13:00", isOpen: false },
      ],
    },
    {
      key: "Saturday",
      value: [
        { id: 1, day: 6, openAt: "7:00", ClosesAt: "13:00", isOpen: false },
      ],
    },
    {
      key: "Sunday",
      value: [
        { id: 1, day: 7, openAt: "7:00", ClosesAt: "13:00", isOpen: false },
      ],
    },
  ]);

  const reInitiateWorkingHours = () => {
    let data = [
      {
        key: "Monday",
        value: [
          { id: 1, day: 1, openAt: "7:00", ClosesAt: "13:00", isOpen: false },
        ],
      },
      {
        key: "Tuesday",
        value: [
          { id: 1, day: 2, openAt: "7:00", ClosesAt: "13:00", isOpen: false },
        ],
      },
      {
        key: "Wednesday",
        value: [
          { id: 1, day: 3, openAt: "7:00", ClosesAt: "13:00", isOpen: false },
        ],
      },
      {
        key: "Thursday",
        value: [
          { id: 1, day: 4, openAt: "7:00", ClosesAt: "13:00", isOpen: false },
        ],
      },
      {
        key: "Friday",
        value: [
          { id: 1, day: 5, openAt: "7:00", ClosesAt: "13:00", isOpen: false },
        ],
      },
      {
        key: "Saturday",
        value: [
          { id: 1, day: 6, openAt: "7:00", ClosesAt: "13:00", isOpen: false },
        ],
      },
      {
        key: "Sunday",
        value: [
          { id: 1, day: 7, openAt: "7:00", ClosesAt: "13:00", isOpen: false },
        ],
      },
    ];
    setWorkingHrs(data);
  };

  const prepareEditDish = async (item: any) => {
    setBranchId(item._id);
    setLoading(true);
    try {
      await api
        .get(
          "api/identity/restaurant-branch/" +
            item._id +
            "?restaurantId=" +
            restaurantId
        )
        .then(function (response) {
          setLoading(false);
          console.log("sucess respp get category");
          console.log(response.data);
          setName(response.data.data.name);
          setEmail(response.data.data.email);
          setTagline(response.data.data.tagline);
          setAddress(response.data.data.address);
          setcontactNumber(response.data.data.contactNumber);
          setLat(response.data.data.mapLocationDetail.latitude);
          setLng(response.data.data.mapLocationDetail.longitude);
          setIsOpenNow(response.data.data.isOpenNow);
          setContactPersonContact(
            response.data.data.contactPerson.contactNumber
          );
          setContactPersonEmail(response.data.data.contactPerson.email);
          setContactPersonName(response.data.data.contactPerson.name);
          setBranchId(item._id);

          prepareWorkingHours(response.data.data.workingHour);
          handleShow();

          return response.data;
        })
        .catch(function (error) {
          console.log("Show error notification!");
          message.error("Error in API call!");
          setLoading(false);
          console.log(error);
          return Promise.reject(error);
        });
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  const prepareWorkingHours = async (item: any) => {
    setWorkingHrs([]);
    let data = [];
    for (let index = 0; index < item.length; index++) {
      const element = item[index];
      let dataIndex = checkIfKeyPresent(item[index], data);
      if (dataIndex >= 0) {
        let newData = data[dataIndex];
        let obj1 = {
          id: newData.value.length + 1,
          day: element.day,
          openAt: element.startTime,
          ClosesAt: element.endTime,
          isOpen: element.isOpen,
        };
        newData.value.push(obj1);
        // data[dataIndex]=newData;
        // data = data.map(u => u.key !== newData.key ? u : newData);
      } else {
        let obj1 = {
          id: index + 1,
          day: element.day,
          openAt: element.startTime,
          ClosesAt: element.endTime,
          isOpen: element.isOpen,
        };

        let valueArray = [];
        valueArray.push(obj1);
        let obj = {
          key: getDay(element),
          value: valueArray,
        };
        data.push(obj);
      }
    }
    console.log(JSON.stringify(data));
    setWorkingHrs(data);
  };

  const checkIfKeyPresent = (item: any, data: any) => {
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      if (element.key === getDay(item)) {
        return index;
      }
    }
    return -1;
  };

  const getDay = (item: any) => {
    if (item.day === 1) {
      return "Monday";
    } else if (item.day === 2) {
      return "Tuesday";
    } else if (item.day === 3) {
      return "Wednesday";
    } else if (item.day === 4) {
      return "Thursday";
    } else if (item.day === 5) {
      return "Friday";
    } else if (item.day === 6) {
      return "Saturday";
    } else if (item.day === 7) {
      return "Sunday";
    }
  };

  const downloadQR = () => {
    const canvas = document.getElementById("QRCode") as HTMLCanvasElement;
    if (null != canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = "123456.png";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  const handleLocationSelect = (placeName, lat, lng) => {
    setAddress(placeName);
    setLat(lat);
    setLng(lng);
    // You can now use these values as needed in your parent component
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div>
            <div className={`body-area${showNav ? " body-pd" : ""}`}>
              <SideBarHeader showNav={showNav} setShowNav={setShowNav} />
              <div className={`l-navbar${showNav ? " showSideNav" : ""}`}>
                <nav className="sideNav">
                  <SideMenu />
                </nav>
              </div>
              <div className="pt-4 pb-4">
                <section className="main shadow-sm">
                  <div className="row mb-4">
                    <div className="col">
                      {/* <h4>Branch List</h4> */}
                      <h4>{SidebarSection.BRANCH_LIST}</h4>
                    </div>
                    <div className="col text-end d-flex ">
                      <input
                        placeholder="Search by Branch Name..."
                        value={search}
                        onChange={(e: any) => handleSearchChange(e)}
                        type="text"
                        name="search"
                        className="form-control search"
                        id="Search"
                        aria-describedby="emailHelp"
                      />

                      <button
                        type="button"
                        className="btn addbtn"
                        onClick={viewAddBranch}
                      >
                        <i className="bi bi-clipboard-plus"></i>
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr className="table-active">
                            <th scope="col">#</th>
                            <th scope="col">BRANCH NAME</th>
                            <th scope="col">BRANCH CODE</th>
                            <th scope="col">EMAIL ID</th>
                            <th scope="col">BRANCH PHONE NUMBER</th>
                            {/* <th scope="col">QR CODE</th> */}
                            <th scope="col">STATUS</th>
                            <th scope="col">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          {branchData !== null &&
                            (branchData as any[]).map((item, index) => (
                              <tr key={index}>
                                <td>{(currentPage - 1) * 10 + index + 1}</td>
                                <td>{item.name}</td>
                                <td>{item.code}</td>
                                <td>{item.email}</td>
                                <td>{item.contactNumber}</td>
                                <td>
                                  {item.status === "active" ? (
                                    <span className="badge bg-success rounded-pill">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="badge bg-error rounded-pill"></span>
                                  )}
                                  {item.status === "inactive" ? (
                                    <span className="badge bg-error rounded-pill">
                                      Inactive
                                    </span>
                                  ) : (
                                    <span className="badge bg-error rounded-pill"></span>
                                  )}
                                  {item.status === "suspended" ? (
                                    <span className="badge bg-warning rounded-pill">
                                      Suspended
                                    </span>
                                  ) : (
                                    <span className="badge bg-error rounded-pill"></span>
                                  )}
                                  {item.status === "creating" ? (
                                    <span className="badge bg-warning rounded-pill">
                                      Creating
                                    </span>
                                  ) : (
                                    <span className="badge bg-error rounded-pill"></span>
                                  )}
                                  {item.status === "waiting_for_approval" ? (
                                    <span className="badge bg-warning rounded-pill">
                                      waitingForApproval
                                    </span>
                                  ) : (
                                    <span className="badge bg-error rounded-pill"></span>
                                  )}
                                  &nbsp;
                                  <button
                                    className="btn-action rounded-circle act-btn"
                                    onClick={() =>
                                      handleStatusUpdateShow(item._id)
                                    }
                                  >
                                    <i className="bi bi-pencil-square"></i>
                                  </button>
                                </td>
                                <td>
                                  <button
                                    onClick={() => prepareEditDish(item)}
                                    className="btn-action rounded-circle act-btn "
                                  >
                                    <i className="bi bi-pencil-square "></i>
                                  </button>
                                  &nbsp;
                                  <button
                                    onClick={() => handleDeleteShow(item._id)}
                                    className="btn-action rounded-circle act-btn "
                                  >
                                    <i className="bi bi-trash "></i>
                                  </button>
                                  &nbsp;
                                  <button
                                    onClick={() =>
                                      handleViewBranchDetailsShow(item._id)
                                    }
                                    className="btn-action rounded-circle act-btn "
                                  >
                                    <i className="bi bi-eye "></i>
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      <div className=" d-flex  justify-content-end pe-md-5">
                        {!loading && (
                          <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            setCurrentPage={setCurrentPage}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>

          <ToastContainer />
        </div>
      )}

      {showModal && (
        <div
          className="modal"
          tabIndex={-1}
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Branch Details
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleClose}
                ></button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col">
                      <div className="mb-3">
                        <label className="form-label">
                          Branch Name<span className="text-danger"> *</span>
                        </label>

                        <input
                          value={name}
                          onChange={(e: any) => handleChange(e)}
                          onFocus={() => setShowMap(false)}
                          type="text"
                          name="name"
                          placeholder="Enter branch Name"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                        {errors.name && (
                          <span className="error-msg">{errors.name}</span>
                        )}
                      </div>
                      <div className="mb-3">
                        <label className="form-label">
                          {" "}
                          Branch Address <span className="text-danger"> *</span>
                        </label>
                        <input
                          value={address}
                          onChange={(e: any) => handleChange(e)}
                          onFocus={() => setShowMap(true)}
                          type="text"
                          name="address"
                          placeholder="Enter branch address"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                        {errors.address && (
                          <span className="error-msg">{errors.address}</span>
                        )}
                      </div>
                    </div>
                    <div className="col">
                      <div className="mb-3">
                        <label className="form-label">
                          Email Id <span className="text-danger"> *</span>
                        </label>
                        <input
                          value={email}
                          onChange={(e: any) => handleChange(e)}
                          onFocus={() => setShowMap(false)}
                          type="text"
                          name="email"
                          placeholder="Enter email id"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                        {errors.email && (
                          <span className="error-msg">{errors.email}</span>
                        )}
                      </div>
                      <div className="mb-3">
                        <label className="form-label">
                          Phone No. <span className="text-danger"> *</span>
                        </label>
                        <input
                          value={contactNumber}
                          onChange={(e: any) => handleChange(e)}
                          onFocus={() => setShowMap(false)}
                          type="text"
                          name="contactNumber"
                          placeholder="Enter phone number"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                        {errors.contactNumber && (
                          <span className="error-msg">
                            {errors.contactNumber}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  {showMap && (
                    <div className="mt-2 mb-2 map-Section">
                      <p className="text-lg ml-1 mb-1">
                        Pick Your Restaurant Location
                      </p>
                      <GoogleMapComp onLocationSelect={handleLocationSelect} />
                    </div>
                  )}
                  <div className="row">
                    <div className="col">
                      <div className="mb-3">
                        <label className="form-label">Tagline</label>
                        <input
                          value={tagline}
                          onChange={(e: any) => setTagline(e.target.value)}
                          onFocus={() => setShowMap(false)}
                          type="text"
                          name="tagline"
                          placeholder="Enter tagline"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">
                          {" "}
                          Contact Person Name{" "}
                          <span className="text-danger"> *</span>
                        </label>
                        <input
                          value={contactPersonName}
                          onChange={(e: any) => handleChange(e)}
                          onFocus={() => setShowMap(false)}
                          type="text"
                          placeholder="Enter contact person name"
                          name="contactPersonName"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                        {errors.contactNumber && (
                          <span className="error-msg">
                            {errors.contactNumber}
                          </span>
                        )}
                      </div>
                      <div className="mb-3">
                        <label className="form-label">
                          Working hours <span className="text-danger"> *</span>
                        </label>
                      </div>
                    </div>
                    <div className="col">
                      <div className="mb-3">
                        <label className="form-label">
                          Contact Person Email Id{" "}
                          <span className="text-danger"> *</span>
                        </label>
                        <input
                          value={contactPersonEmail}
                          onChange={(e: any) => handleChange(e)}
                          onFocus={() => setShowMap(false)}
                          type="text"
                          name="contactPersonEmail"
                          placeholder="Enter contact person email id"
                          className="form-control"
                          aria-describedby="emailHelp"
                        />
                        {errors.contactPersonEmail && (
                          <span className="error-msg">
                            {errors.contactPersonEmail}
                          </span>
                        )}
                      </div>
                      <div className="mb-3">
                        <label className="form-label">
                          Contact Person's Number{" "}
                          <span className="text-danger"> *</span>
                        </label>
                        <input
                          value={contactPersonContact}
                          onChange={(e: any) => handleChange(e)}
                          onFocus={() => setShowMap(false)}
                          type="text"
                          name="contactPersonContact"
                          placeholder="Enter contact person's number"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                        {errors.contactPersonContact && (
                          <span className="error-msg">
                            {errors.contactPersonContact}
                          </span>
                        )}
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Temporarily Closed</label>
                        &nbsp;
                        <Checkbox
                          name="isOpenNow"
                          value={isOpenNow}
                          defaultChecked={isOpenNow}
                          onChange={(e: any) => handleChange(e)}

                          // onChange={(e: any) => setIsOpenNow(e.target.value)}
                        ></Checkbox>
                        {errors.isOpenNow && (
                          <span className="error-msg">{errors.isOpenNow}</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="days-section">
                    {workingHrs.map((item: any, index: any) => {
                      return (
                        <>
                          <div>{item.key}</div>
                          <div
                            className="row d-flex justify-content-between"
                            key={item}
                          >
                            {item.value.map((item1: any, index1: any) => {
                              return (
                                <div className="row">
                                  <div className="col-3">
                                    <span>
                                      <label>Opens At</label>
                                      <p>
                                        {" "}
                                        <TimePicker
                                          name="startTime"
                                          size="small"
                                          defaultValue={dayjs(
                                            item1.openAt,
                                            format
                                          )}
                                          format={format}
                                          onChange={(
                                            e: any,
                                            timeString: string
                                          ) =>
                                            updateWorkingHoursArray(
                                              timeString,
                                              item,
                                              item1,
                                              index,
                                              index1,
                                              "startTime"
                                            )
                                          }
                                          // onOk={(e) => {
                                          //   // setTimeString(timeString);
                                          //   console.log(item+item1+index+index1);
                                          //   console.log("time-" + e);
                                          //   // console.log(timeString);
                                          // }}
                                        />
                                      </p>
                                    </span>
                                  </div>
                                  <div className="col-3">
                                    <span>
                                      <label>Closes At</label>
                                      <p>
                                        {" "}
                                        <TimePicker
                                          name="endTime"
                                          size="small"
                                          defaultValue={dayjs(
                                            item1.ClosesAt,
                                            format
                                          )}
                                          format={format}
                                          onChange={(
                                            e: any,
                                            timeString: string
                                          ) =>
                                            updateWorkingHoursArray(
                                              timeString,
                                              item,
                                              item1,
                                              index,
                                              index1,
                                              "endTime"
                                            )
                                          }
                                        />
                                      </p>
                                    </span>
                                  </div>
                                  <div className="col-3 d-flex align-items-center">
                                    <Checkbox
                                      defaultChecked={item1.isOpen}
                                      name="isOpen"
                                      value={item1.isOpen}
                                      onChange={(e: any) =>
                                        updateWorkingHoursArray(
                                          e,
                                          item,
                                          item1,
                                          index,
                                          index1,
                                          "isOpen"
                                        )
                                      }
                                    >
                                      Closed
                                    </Checkbox>
                                  </div>
                                  <div className="col-3 d-flex align-items-center">
                                    <PlusOutlined
                                      onClick={() =>
                                        addWorkingHours(
                                          item,
                                          index,
                                          item1,
                                          index1
                                        )
                                      }
                                      style={{ fontSize: "150%" }}
                                    />
                                    {item.value.length > 1 && (
                                      <span style={{ marginLeft: "12px" }}>
                                        <DeleteOutlined
                                          onClick={() =>
                                            deleteWorkingHours(
                                              item,
                                              index,
                                              item1,
                                              index1
                                            )
                                          }
                                          style={{ fontSize: "150%" }}
                                        />
                                      </span>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <hr />
                        </>
                      );
                    })}
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    onClick={addOrUpdateBranch}
                    className="btn btn-primary"
                  >
                    Save changes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {showDeleteModal && (
        <div
          className="modal"
          tabIndex={-1}
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Delete branch?
                </h1>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleDeleteClose}
                ></button>
              </div>
              <div className="delete-text">
                {" "}
                Are you sure you want to delete?
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleDeleteClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={deleteBranch}
                  className="btn btn-primary"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showStatusUpdateModal && (
        <div
          className="modal"
          tabIndex={-1}
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Update status?
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleStatusUpdateClose}
                ></button>
              </div>
              <div className="delete-text">
                <div className="mb-12 col-md-12">
                  <label className="form-label">
                    Select status <span className="text-danger"> *</span>
                  </label>
                  <div>
                    <select
                      className="form-control down-arrow"
                      value={branchStatus}
                      onChange={(e) => setBranchStatus(e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                      <option value="suspended">Suspended</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleStatusUpdateClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={updateStatus}
                  className="btn btn-primary"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showViewBranchModal && (
        <div
          className="modal modal-lg"
          tabIndex={-1}
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Branch Details
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleViewBranchClose}
                ></button>
              </div>
              <div className="delete-text">
                <div className="table-responsive">
                  <table className="table table-bordered view-table">
                    <tbody>
                      <tr>
                        <th>BRANCH NAME</th>
                        <td>{branchDetails?.name}</td>
                        <th>BRANCH CODE</th>
                        <td>{branchDetails?.code}</td>
                      </tr>
                      <tr>
                        <th>BRANCH STATUS </th>
                        <td>
                          <div>
                            {branchDetails.status === "active" ? (
                              <span className="badge bg-success rounded-pill">
                                Active
                              </span>
                            ) : (
                              <span className="badge bg-error rounded-pill"></span>
                            )}
                            {branchDetails.status === "inactive" ? (
                              <span className="badge bg-error rounded-pill">
                                Inactive
                              </span>
                            ) : (
                              <span className="badge bg-error rounded-pill"></span>
                            )}
                            {branchDetails.status === "suspended" ? (
                              <span className="badge bg-warning rounded-pill">
                                Suspended
                              </span>
                            ) : (
                              <span className="badge bg-error rounded-pill"></span>
                            )}
                            {/* {branchDetails?.isActive ? (
                              <span className="badge bg-success rounded-pill">
                                Active
                              </span>
                            ) : (
                              <span className="badge bg-error rounded-pill">
                                InActive
                              </span>
                            )} */}
                          </div>
                        </td>
                        <th>TAGLINE </th>
                        <td>{branchDetails?.tagline}</td>
                      </tr>
                      <tr>
                        <th>ADDRESS</th>
                        <td>{branchDetails?.address}</td>
                        <th>CONTACT NUMBER</th>
                        <td>{branchDetails?.contactNumber}</td>
                      </tr>
                      <tr>
                        <th> BRANCH EMAIL ID</th>
                        <td>{branchDetails?.email}</td>
                        <th>OPEN</th>
                        <td>
                          <div>
                            {!branchDetails?.isOpenNow ? (
                              <span className="badge bg-success rounded-pill">
                                Open
                              </span>
                            ) : (
                              <span className="badge bg-error rounded-pill">
                                Closed
                              </span>
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>CONTACT PERSON</th>
                        <td>{branchDetails?.contactPerson.name}</td>
                        <th>CONTACT PERSON NUMBER</th>
                        <td>{branchDetails?.contactPerson.contactNumber}</td>
                      </tr>
                      <tr>
                        <th>CONTACT PERSON EMAIL ID</th>
                        <td>{branchDetails?.contactPerson.email}</td>
                        {/* <th>STATUS</th>
                        <td>{branchDetails?.status}</td> */}
                        <th>BRANCH MENU</th>
                        <td>
                          <Button
                            style={{
                              background: "rgb(18, 153, 1)",
                              color: "white",
                            }}
                            onClick={() => {
                              const menuId = branchDetails?.assignedMenuId;
                              if (menuId) {
                                viewBranchMenu(menuId);
                              } else {
                                message.warning(
                                  "Menues not assigned to this branch"
                                );
                              }
                            }}
                          >
                            View menu
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <th>OPENING HOURS</th>
                        <td>
                          {sortedData !== null &&
                            (sortedData as any[]).map((item, index) => (
                              <div key={index}>
                                <div>
                                  {item.day === 1
                                    ? "Monday"
                                    : item.day === 2
                                    ? "Tuesday"
                                    : item.day === 3
                                    ? "Wednesday"
                                    : item.day === 4
                                    ? "Thursday"
                                    : item.day === 5
                                    ? "Friday"
                                    : item.day === 6
                                    ? "Saturday"
                                    : item.day === 7
                                    ? "Sunday"
                                    : ""}

                                  {!item.isOpen ? (
                                    <>
                                      <br />
                                      <span>
                                        Opening time: {item.startTime} Closing
                                        time: {item.endTime}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <span> </span>
                                      <span className="badge text-bg-danger">
                                        Closed
                                      </span>
                                    </>
                                  )}
                                </div>

                                <hr />
                              </div>
                            ))}
                        </td>
                      </tr>
                      <tr>
                        <th>QR CODE</th>
                        <td>
                        {!isApproved && (
                          
                        
                        <div className="d-flex justify-content-end">
                          <span className="text-danger">
                            * Restaurant is not Approved please contact us for more details
                          </span>
                          
                          <br />
                        <button
                          type="button"
                          className="btn"
                          onClick={handleOpenContactUs}
                          style={{
                            backgroundColor: 'rgb(254, 108, 0)', // Custom background color
                            color: 'white', 
                          }}
                        >
                          Contact Us
                        </button>
                      </div>
                        )}
                          {" "}
                          <QRCode
                            value={
                              baseURL +
                              "restaurant?a=" +
                              branchDetails?.assignedMenuId +
                              "&b=" +
                              branchId +
                              "&c=" +
                              restaurantId
                            }
                            size={300}
                            includeMargin={true}
                            id={"QRCode"}
                            level={"H"}
                          />
                          <br />
                          {/* <a onClick={downloadQR} role="button"> Download QR </a> */}
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={downloadQR}
                          >
                            Download QR
                          </button>
                        </td>
                        <th></th>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={handleViewBranchClose}
                  className="btn btn-primary"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {show && (
        <div
          className="modal"
          tabIndex={-1}
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Contact Us
                </h1>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleCloseContactUs}
                ></button>
              </div>
              <div className="delete-text">
                {" "}
                Contact Us :- +971 50724 5494
                <br />
                Email Us :- sales@innovatechsoft.com
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleCloseContactUs}
                >
                  OK
                </button>
                
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Branches;
