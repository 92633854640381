import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Header.css"
export default function Header() {
  const { pathname } = useLocation();
  const [isShowRegister, setIsShowRegister] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
    const dropdownRef = useRef(null); // Reference to the dropdown
    const location = useLocation(); // Used to detect navigation changes

    // Toggle the menu when the hamburger or cross icon is clicked
    const toggleMenu = () => {
        console.log(isMenuOpen);
         setIsMenuOpen(!isMenuOpen); // This will toggle between open and close
        console.log(isMenuOpen);
    };

    // Close dropdown if user clicks outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsMenuOpen(false); // Close the dropdown if clicked outside
            }
        };

        if (isMenuOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isMenuOpen]);

    // Close dropdown on route change (when navigating to a different page)
    useEffect(() => {
        setIsMenuOpen(false);
    }, [location.pathname]);
  useEffect(() => {
    if(pathname==="/restaurant"  || pathname.startsWith("/restaurant")){
      console.log("hide register btn");
      setIsShowRegister(false);
    } else {
      setIsShowRegister(true);
    }
  }, [pathname]);

  // you can check a more conditions here
  if (
    pathname === "/sidebar" ||
    pathname === "/menu" ||
    pathname === "/dish" ||
    pathname === "/restaurants" ||
    pathname === "/profile" ||
    pathname === "/theme" ||
    pathname === "/admin-panel" ||
    pathname === "/restaurant-theme" ||
    pathname === "/category" ||
    pathname === "/branch-menu" ||
    pathname === "/branches" ||
    pathname === "/menu-creation" ||
    pathname === "/document-upload"
  )
    return null;

  return (
    <>
      {/* ======= Header ======= */}
      <header
        id="header"
        className="header fixed-top d-flex align-items-center border-bottomHeader"
        style={{ borderBottom: "0.5px solid #eeebeb" }}
      >
        <div className="container d-flex justify-content-between ">
          <div className="d-flex">
            <a
              style={{ textDecoration: "none" }}
              href="/"
              className="logo d-flex align-items-center me-auto me-lg-0"
            >
              {/* Uncomment the line below if you also wish to use an image logo */}
              <div className="h-100 " style={{ width: "40px", alignContent:"center" }}>
                {/* <img
                  src="/img/logo1.jpeg"
                  alt="logo"
                  style={{ objectFit: "fill", width: "100%" }}
                /> */}
                <img
                  src="/img/Menu Dealz Logo-01.jpg"
                  alt="logo"
                  style={{ objectFit: "cover", height: "40px" }}
                />
              </div>
              <h1>
                <span style={{ color: "#129901" }}>MENU</span>
                <span style={{ color: "#fe6c00" }}>DEALZ</span>
              </h1>
            </a>
            <nav id="navbar" className="navbar">
              <ul>
                {/* {menu?.map((hederName:any ,index:number)=>{
            return<li key={index}>
            <a href={hederName.path}>{hederName.name}</a>
            </li>
            })} */}

                {/* <li className="dropdown">
              <a href="#">
                <span>Drop Down</span>{" "}
                <i className="bi bi-chevron-down dropdown-indicator" />
              </a>
              <ul>
                <li>
                  <a href="#">Drop Down 1</a>
                </li>
                <li className="dropdown">
                  <a href="#">
                    <span>Deep Drop Down</span>{" "}
                    <i className="bi bi-chevron-down dropdown-indicator" />
                  </a>
                  <ul>
                    <li>
                      <a href="#">Deep Drop Down 1</a>
                    </li>
                    <li>
                      <a href="#">Deep Drop Down 2</a>
                    </li>
                    <li>
                      <a href="#">Deep Drop Down 3</a>
                    </li>
                    <li>
                      <a href="#">Deep Drop Down 4</a>
                    </li>
                    <li>
                      <a href="#">Deep Drop Down 5</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#">Drop Down 2</a>
                </li>
                <li>
                  <a href="#">Drop Down 3</a>
                </li>
                <li>
                  <a href="#">Drop Down 4</a>
                </li>
              </ul>
            </li>
            <li>
              <a href="#contact">Contact</a>
            </li> */}
              </ul>
            </nav>
          </div>

          {/* .navbar */}
          <div className="d-flex align-items-center">
            {isShowRegister ? (
              
              
              <header className="header-container">
            <nav className="header-nav">
                <div className="nav-links">
                    <Link to="/home-page" className="header-nav-link">Home</Link>
                    &emsp;
                    <Link to="/contact-us" className="header-nav-link">Contact Us</Link>
                </div>

                <div className="nav-buttons">
                    <Link to="/login" className="header-nav-button login-button">Login</Link>
                    <Link to="/register" className="header-nav-button create-menu-button">Register</Link>
                </div>

                {/* Hamburger or Cross icon for small screens */}
                <i
                    className={`bi bi-list hamburger-icon`}
                    onClick={toggleMenu} // Toggle dropdown on click
                ></i>
            </nav>

            {/* Dropdown menu (only visible on small screens when the hamburger is clicked) */}
            {isMenuOpen && (
                <div className="dropdown-menu" ref={dropdownRef}>
                    <Link to="/home-page" className="dropdown-link" onClick={toggleMenu}>Home</Link>
                    <Link to="/contact-us" className="dropdown-link" onClick={toggleMenu}>Contact Us</Link>
                    <Link to="/login" className="dropdown-link" onClick={toggleMenu}>Login</Link>
                    <Link to="/register" className="dropdown-link" onClick={toggleMenu}>Register</Link>
                </div>
            )}
        </header>
         
              
            ) : (
              <></>
            )}
            {/* <Link style={{textDecoration:'none'}}  className="btn-book-a-table" to="/sidebar">  Admin panel</Link> */}
          </div>

          {/* <i className="mobile-nav-toggle mobile-nav-show bi bi-list" />
          <i className="mobile-nav-toggle mobile-nav-hide d-none bi bi-list" /> */}
        </div>
      </header>
      {/* End Header */}
    </>
  );
}
