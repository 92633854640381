import React, { useEffect, useState } from "react";
import RestaurantMenu from "./restaurantMenu/DefaultMenus";
// import { useParams } from "react-router-dom";
import api from "../../api";
import { ToastContainer, toast } from "react-toastify";
import { RestaurantDetailInterface, branchDetailInterface } from "../restaurant-list/interFace";
import { constants } from "buffer";
import BuffetMenu from "./restaurantMenu/BuffetMenu";
import DefaultMenu from "./restaurantMenu/DefaultMenus";
import EventMenu from "./restaurantMenu/EventMenu";
import TodaySpecial from "./restaurantMenu/TodaySpecial";
import ComboOffer from "./restaurantMenu/ComboOffer";
import DiscountedOffer from "./restaurantMenu/DiscountedOffer";

export default function RestaurantView() {
  // const { menuId, branchId, restaurantId } = useParams<{
  //   menuId?: string;
  //   branchId?: string;
  //   restaurantId?: string;
  // }>();

  const [menuId, setMenuId] = useState("");
  const [branchId, setBranchId] = useState("");
  const [restaurantId, setRestaurantId] = useState("");
  const [status, setStatus] = useState(false);
  const [defaultMenus, setDefaultMenus] = useState([]);
  const [buffetMenus, setBuffetMenus] = useState([]);
  const [eventMenus, setEventMenus] = useState([]);
  const [todaySpecialMenus, setTodaySpecialMenus] = useState([]);
  const [comboOffer, setComboOffer] = useState([]);
  const [discountedOffer, setDiscountedOffer] = useState([]);
  const [selectedCurrencyData, setSelectedCurrencyData] = useState(String);

  const [restaurantDetail, setRestaurantDetail] =
    useState<RestaurantDetailInterface>();
  const [branchDetail, setBranchDetail] =
    useState<branchDetailInterface>();

  useEffect(() => {
    // console.log("menuId: " + menuId + " branchId: " + branchId + " restaurantId: " + restaurantId);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const a = params.get("a");
    const b = params.get("b");
    const c = params.get("c");
    setMenuId(a);
    setBranchId(b);
    setRestaurantId(c);
  }, []);
  useEffect(() => {
    if (restaurantId && branchId && menuId) {
      fetchRestaurantDetailData();
      fetchBranchDetailData();
      getBranchAllDetails();
    }
  }, [restaurantId, branchId, menuId]);
  const getBranchAllDetails = async () => {
    let menuIdBlank = "{menuId}";
    try {
      await api
        .get(
          "/api/distributor/branch-menu/" +
            menuIdBlank +
            "?branchId=" +
            branchId +
            "&restaurantId=" +
            restaurantId +
            "&isPreview=true"
        )
        .then(function (response) {
          const defaultMenuIds = response.data.data.defaultMenus.map(
            (menu) => menu._id
          );
          const buffetMenuIds = response.data.data.buffetMenus.map(
            (menu) => menu._id
          );
          const eventMenuIds = response.data.data.eventMenus.map(
            (menu) => menu._id
          );
          const todaySpecialMenuIds = response.data.data.todaySpecials.map(
            (menu) => menu._id
          );
          const ComboOfferIds = response.data.data.comboSpecialOffers.map(
            (menu) => menu._id
          );
          setBranchDetail(response.data.data.branch)
          const discountedOfferIds =
            response.data.data.discountedSpecialOffers.map((menu) => menu._id);
          setDefaultMenus(defaultMenuIds);
          setBuffetMenus(buffetMenuIds);
          setEventMenus(eventMenuIds);
          setTodaySpecialMenus(todaySpecialMenuIds);
          setComboOffer(ComboOfferIds);
          setDiscountedOffer(discountedOfferIds);

          const allMenus = {
            "Event Meal": eventMenuIds,
            "Flash Dealz": discountedOfferIds,
            "Combo Dealz": ComboOfferIds,
            "Today Special": todaySpecialMenuIds,
            "Regular Menu": defaultMenuIds,
            "Buffet": buffetMenuIds,
          };
  
          // Find the first non-empty menu
          const firstNonEmptyMenu = Object.keys(allMenus).find(
            (menuType) => allMenus[menuType] && allMenus[menuType].length > 0
          );
  
          if (firstNonEmptyMenu) {
            setSelectedMenuType(firstNonEmptyMenu);
          } else {
            setSelectedMenuType("Regular Menu"); // Default fallback
          }
        })
        .catch(function (error) {
          console.log("Show error notification!");
          console.log(error);
          return Promise.reject(error);
        });
    } catch (error) {
      console.error("There was an error!", error);
    }
  };
  const fetchBranchDetailData = async () => {
    try {
      await api
        .get(
          "/api/identity/restaurant-branch/" +
            branchId +
            "?restaurantId=" +
            restaurantId
        )
        .then(function (response) {
          
          console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          console.log("Show error notification!");
          console.log(error);
          return Promise.reject(error);
        });
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  const fetchRestaurantDetailData = async () => {
    try {
      if ("" !== restaurantId && null !== restaurantId) {
        await api
          .get(`/api/identity/restaurant/${restaurantId}`)
          .then(function (response) {
            const restaurantDetailData = response.data.data;
            console.log("*******1");
            console.log(response.data.data.isApproved);
            setStatus(response.data.data.isApproved);
            localStorage.setItem(
              "currencyDetails",
              restaurantDetailData.defaultCurrency.symbol
            );
            setSelectedCurrencyData(restaurantDetailData.defaultCurrency.code)
            
            setRestaurantDetail(restaurantDetailData);
            return response.data;
          })
          .catch(function (error) {
            console.log("Show error notification!");
            toast.error("Error in api call!");
            console.log(error);
            return Promise.reject(error);
          });
      }
    } catch (error) {
      console.error("Error fetching restaurant detail data:", error);
    }
  };
  useEffect(() => {
    const textWatermark = "Preview";
    let fullTextWatermark = "";
    const n = 10000;
    for (let i = 0; i < n; i++) {
      fullTextWatermark += " " + textWatermark;
    }
    document.getElementById("watermark").innerHTML = fullTextWatermark;
  }, []);
  const menuTypes = [
    { type: "Event Meal", data: eventMenus },
    { type: "Flash Dealz", data: discountedOffer },
    { type: "Combo Dealz", data: comboOffer },
    { type: "Today Special", data: todaySpecialMenus },
    { type: "Regular Menu", data: defaultMenus },
    { type: "Buffet", data: buffetMenus },
    
  ];
  const [selectedMenuType, setSelectedMenuType] = useState(menuTypes[4].type);
  const renderMenus = () => {
    switch (selectedMenuType) {
      case "Regular Menu":
        return defaultMenus.length > 0 ? (
          defaultMenus.map((menuId) => (
            <DefaultMenu
              key={menuId}
              data={{
                menuId: menuId,
                branchId: branchId,
                restaurantId: restaurantId,
                selectedCurrency:selectedCurrencyData,
              }}
            />
          ))
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            
          </div>
        );
      case "Buffet":
        return (
          <div className="row">
            {buffetMenus.length > 0 ? (
              buffetMenus.map((menuId) => (
                <div className="col-sm-6 col-md-6 col-lg-3" key={menuId}> 
                <BuffetMenu
                  data={{
                    menuId: menuId,
                    branchId: branchId,
                    restaurantId: restaurantId,
                    selectedCurrency:selectedCurrencyData,
                  }}
                />
              </div>
              ))
            ) : (
              <div
                className="col-12 d-flex justify-content-center align-items-center"
                style={{ height: "200px" }}
              >
                
              </div>
            )}
          </div>
        );
      case "Event Meal":
        return (
          eventMenus.length > 0 ? (
              eventMenus.map((menuId) => (
                <div className="row justify-content-center">
                <div className="col-md-8" key={menuId}>
                  <EventMenu
                    data={{
                      menuId: menuId,
                      branchId: branchId,
                      restaurantId: restaurantId,
                      selectedCurrency: selectedCurrencyData,
                    }}
                  />
                </div>
              </div>

              ))
            ) : (
              <div
                className="col-12 d-flex justify-content-center align-items-center"
                style={{ height: "200px" }}
              >
                
              </div>
            )
        );
      case "Today Special":
        return (
          <div className="row">
            {todaySpecialMenus.length > 0 ? (
              todaySpecialMenus.map((menuId) => (
                <div className="col-sm-6 col-md-4 col-lg-3" style={{padding:"0px"}} key={menuId}>
                  <TodaySpecial
                    data={{
                      menuId: menuId,
                      branchId: branchId,
                      restaurantId: restaurantId,
                      selectedCurrency:selectedCurrencyData,
                    }}
                  />
                </div>
              ))
            ) : (
              <div
                className="col-12 d-flex justify-content-center align-items-center"
                style={{ height: "200px" }}
              >
                
              </div>
            )}
          </div>
        );
      case "Combo Dealz":
        return (
          <div className="row">
            {comboOffer.length > 0 ? (
              comboOffer.map((menuId) => (
                <div className="col-sm-6 col-md-4 col-lg-3" style={{padding:"0px"}} key={menuId}>
                  <ComboOffer
                    data={{
                      menuId: menuId,
                      branchId: branchId,
                      restaurantId: restaurantId,
                      selectedCurrency:selectedCurrencyData,
                    }}
                  />
                </div>
              ))
            ) : (
              <div
                className="col-12 d-flex justify-content-center align-items-center"
                style={{ height: "200px" }}
              >
                
              </div>
            )}
          </div>
        );
      case "Flash Dealz":
        return (
          <div className="row">
            {discountedOffer.length > 0 ? (
              discountedOffer.map((menuId) => (
                <div className="col-sm-6 col-md-4 col-lg-3" style={{padding:"0px"}} key={menuId}>
                  <DiscountedOffer
                    data={{
                      menuId: menuId,
                      branchId: branchId,
                      restaurantId: restaurantId,
                      selectedCurrency:selectedCurrencyData,
                    }}
                  />
                </div>
              ))
            ) : (
              <div
                className="col-12 d-flex justify-content-center align-items-center"
                style={{ height: "200px" }}
              >
                
              </div>
            )}
          </div>
        );

      default:
        return null;
    }
  };
  const [activeTab, setActiveTab] = useState(menuTypes[0]?.type);

  return (
    <div className="container">
      {!status && <p id="watermark"></p>}
      <div className="row">
        <div className="col-md-12">
          <section
            id="hero"
            className="hero d-flex align-items-center herocol"
            style={{ background: "aliceblue" }}
          >
            <div className="container" style={{ textAlign: "center" }}>
              <h5
                data-aos="fade-up mt-3"
                style={{
                  color: "rgb(254, 108, 0)",
                  margin: "20px",
                  fontSize: "25px",
                }}
              >
                {branchDetail?.name}
              </h5>
              <img
                src={restaurantDetail?.attachement[0]?.url}
                width={250}
                height={250}
                alt="MenuDealz"
              />
              <h5
                data-aos="fade-up mt-3"
                style={{ color: "rgb(206 149 0)", margin: "20px" }}
              >
                {branchDetail?.tagline}
                <br />
              </h5>
            </div>
          </section>
          <br />
          {/* Button layout for larger screens */}
          <div className="d-none d-md-flex justify-content-center">
            {menuTypes
              .filter((menu) => menu.data.length > 0)
              .map((menu) => (
                <button
                  key={menu.type}
                  className={`btn btn-lg ${
                    selectedMenuType === menu.type
                      ? "btn-warning"
                      : "btn-outline-dark"
                  } mx-2`}
                  onClick={() => setSelectedMenuType(menu.type)}
                >
                  {menu.type}
                </button>
              ))}
          </div>

          {/* Dropdown layout for smaller screens */}
<div className="d-block d-md-none text-center">
  <style>
    {`
      .scroll-menu::-webkit-scrollbar {
        width: 4px; /* Width of the scrollbar */
        height: 4px; /* Height of the scrollbar for horizontal scrollbars */
      }

      .scroll-menu::-webkit-scrollbar-thumb {
        background-color: darkgray; /* Color of the scrollbar thumb */
        border-radius: 10px; /* Roundness of the scrollbar thumb */
      }

      .scroll-menu::-webkit-scrollbar-track {
        background: transparent; /* Background of the scrollbar track */
      }

      /* Styling for active and non-active tabs */
      .nav-tabs .nav-item .active-tab {
        background-color: #ff6600; /* Orange background for active tab */
        color: white; /* White text for active tab */
        border-radius: 6px; /* Rounded corners for active tab */
      }

      .nav-tabs .nav-item .inactive-tab {
        background-color: transparent; /* Transparent background for inactive tabs */
        color: black; /* Black text for inactive tabs */
        border-radius: 6px; /* Rounded corners for consistency */
      }
    `}
  </style>
  <ul
    className="nav nav-tabs scroll-menu"
    style={{ backgroundColor: "aliceblue" }}
    data-aos="fade-up"
    data-aos-delay={200}
  >
    {menuTypes
      .filter((menu) => menu.data.length > 0)
      .map((menu, index) => (
        <li key={index} className="nav-item">
          <a
            className={`btn ${
              selectedMenuType === menu.type
                ? "active-tab" // Apply the active-tab class if selected
                : "inactive-tab" // Apply the inactive-tab class if not selected
            } mx-2`}
            style={{
              padding: "8px 16px", // Adjust padding to fit the layout
              fontSize: "16px", // Font size closer to the example
            }}
            onClick={() => setSelectedMenuType(menu.type)}
          >
            {menu.type}
          </a>
        </li>
      ))}
  </ul>
</div>

        </div>
      </div>
      <div className="row">
        <div className="col-md-12">{renderMenus()}</div>
      </div>
      <ToastContainer />
    </div>
  );
}
